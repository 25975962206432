<template>
    <el-scrollbar class="home-container">
        <div class="home-content" v-if="!isShowBody">
            <div class="home-content__left">
                <home-notice
                    v-has-permi="['announcement:list']"
                />
                <home-commonly-used></home-commonly-used>
                <home-applications></home-applications>
            </div>
            <div class="home-content__right">
                <personal-infor @isShowMyCurriculum="isShowMyCurriculum"></personal-infor>
                <home-pending></home-pending>
            </div>
        </div>
        <div class="home-content" v-if="isShowBody">
            <course-schedule
                ref="courseScheduleWrapper"
                @closeMyCurriculum="closeMyCurriculum"
            ></course-schedule>
        </div>
        <div class="copyright-wrap">
            河北鑫考科技股份有限公司 版权所有 © 2008-2023 Xinkaoyun.com, All Rights Reserved | 邮编053000 | 电话400-189-0086 | 邮箱：834701@xinkaojiaoyu.con
        </div>
        <!-- 更新公告 -->
        <update-notice :fromPath="fromPath"></update-notice>
    </el-scrollbar>
</template>

<script>
import {mapState} from "vuex";
import HomeNotice from "@/components/scrollWrapper/firstPage/HomeNotice.vue";
import HomeCommonlyUsed from "@/components/scrollWrapper/firstPage/HomeCommonlyUsed.vue";
import HomeApplications from "@/components/scrollWrapper/firstPage/HomeApplications.vue";
import HomeIntroduce from "@/components/scrollWrapper/firstPage/HomeIntroduce.vue";
import PersonalInfor from "@/components/scrollWrapper/firstPage/PersonalInfor.vue";
import HomePending from "@/components/scrollWrapper/firstPage/HomePending.vue";
import {treeFind} from "@/libs/utils";
import CourseSchedule from "./firstPage/MyCurriculum/CourseSchedule.vue";
import UpdateNotice from "@/components/scrollWrapper/firstPage/UpdateNotice.vue";
import { getToken } from "@/libs/auth";
// import browserTool from "browser-tool";

import axios from "axios";

export default {
    name: 'FirstPageScrollWrapper',
    props: {
        fromPath: {
            type: String,
        }
    },
    components: {
        HomeNotice,
        HomeCommonlyUsed,
        HomeApplications,
        HomeIntroduce,
        HomePending,
        PersonalInfor,
        CourseSchedule,
        UpdateNotice,
    },
    computed: {
        ...mapState({
            originRouters: (state) => state.originRouters,
        }),
    },
    data() {
        return {
            introduceRouteName: "SummaryIntroduce",
            isShowBody: false,
        };
    },
    methods: {
        hasPermission(name) {
            return this.originRouters.findIndex((e) => e.name === name) > -1;
        },
        isShowMyCurriculum(e) {
            console.log('eeeee',e);
            this.isShowBody = e;
        },
        closeMyCurriculum() {
            // 关闭我的课表
            this.isShowBody = false;
        }
    },
};
</script>

<style scoped lang="scss">
.home-container {
    font-family: Microsoft YaHei;
    height: calc(100% - 76px);
    .home-content {
        display: flex;
        justify-content: space-between;
        width: 1411px;
        margin: 0 auto;

        &__left {
            width: 930px;
        }

        &__right {
            width: 465px;
        }
    }

    .copyright-wrap {
        width: 100%;
        text-align: center;
        line-height: 13px;
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        margin: 8px auto 26px;
    }
}

::v-deep .el-scrollbar__wrap {
    height: calc(100% + 17px);
}
</style>
