<!-- 跟课记录 -->
<template>
    <div class="center-main">
        <iframe
            :src="link"
            class="center-main__iframe"
            style="background-color:#fff;"
        ></iframe>
    </div>
</template>
<script>
import {getToken} from "@/libs/auth.js";
export default {
    name: "FollowClassRecordScrollWrapper",
    data() {
        return {
            // jumpOri: "http://124.71.224.87:8087/course/index.html#/loading",   // 测试跳转地址
            jumpOri: "https://hzjy.xinkaoyun.com:8082/course/index.html#/loading",  // 正式跳转地址
            jumpOri:"",
            link: "",
        };
    },
    created() {
        console.log("对接旧云校",this.$route)
        this.jumpOri=`https://hzjy.xinkaoyun.com:8082/course/index.html#/loading`
        this.init();
    },
    mounted(){

    },
    methods: {
        init() {
            this.initAssignment();
        },
        initAssignment() {
            this.link = this.jumpOri + `?token=${getToken()}`;
            //清空query的值
            this.$router.push({ query: {} });
            console.log("this.link===============", this.link);
        },
    },
};
</script>
<style scoped lang="scss">
.center-main {
    height: calc(100vh - 113px);
    padding: 10px 10px 10px 0;
    overflow: hidden;

    &__iframe {
        width: 100%;
        height: 100%;
        border: 0;
        border-radius: 4px;
    }
}
</style>
